.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.container{
    position: relative;
    width: 100%;
    max-width: 40rem;
    height: 45rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;

}
.titleContainer{
    color: black;
    display: flex;
    background-color: white;
    flex-direction: row;
    justify-content: center;
    width: 90%;
}

.title {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 3rem;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 1.5rem;
}

.body{
    color: black;
    justify-content: center;
    /* background: linear-gradient(white, #515151);*/
    height: 20rem;
    width: 80%;

}

.closeSection{
    margin-top: 2rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
    cursor: pointer;
}

.closeButton{

}

.divider {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 30%;
    height: 0.2rem;
    background: black;
    border-radius: 5rem;
}
.text{
    font-size: 9px;
    font-weight: bold;
    color: var(--fourthColor);
}
.text:hover{
    color: var(--thirdColor);
}
@media screen and (max-width: 960px) {

    .popup{
        display: flex;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        background-color: white;
    }
}
