.container{
    width: 1.7rem;
    height: 1.7rem;
    background-color: #202020;
    border: 2px solid #B388FF;
    border-radius: 7px;
    box-shadow: 1px 2px 2px 1px rgba(255, 255, 255, 0.50);
    margin: 0.2rem;
}

.container:hover{
    cursor: pointer;
    box-shadow: 1px 2px 2px 2px rgba(220, 220, 220, 0.6);
}

.active{
    width: 1.7rem;
    height: 1.7rem;
    background-color: white;
    border: 2px solid var(--secondColor);
    border-radius: 7px;
    margin: 0.2rem;
}

@media (max-width: 1366px) {
    .container{
        width: 1.7rem;
        height: 1.7rem;
    }
    .active{
        width: 1.7rem;
        height: 1.7rem;
    }
}
@media (max-width: 1024px) {
    .container{
        width: 1.5rem;
        height: 1.5rem;
    }
    .active{
        width: 1.5rem;
        height: 1.5rem;
    }
}
