:root {
  --firstColor: darkred;
  --secondColor: #b388ff;
  --thirdColor: white;
  --fourthColor: black;
  --fifthColor: #3C3B3D;
  --sixthColor: #515151;
  --seventhColor: #202020;
  --eighthColor: #efefef;
  --borderRadSett: 0.5rem;
}

body {
  height: 98vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Maven Pro",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #7c7c7c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*scrollBar*/
/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 1rem;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  /* background: var(--secondColor); */
  border-radius: 10px;
  border: 1px solid white;
  background: linear-gradient(to right, transparent, var(--secondColor), transparent);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to right, transparent, var(--secondColor), transparent);
  border: 3px solid white;

}
::-webkit-scrollbar:hover {
  cursor: pointer;
}

@media (max-width: 1366px) {
  body {
    height: 100%;
    width: 100%;
    padding: 2% 0;
  }
}