.btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    line-height: 42px;
    padding-left: 5px;
    padding-right: 5px;
    border: 2px;
    font-weight: 500;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    border-radius: 5px;
}

/*Da modificare - BOTTONI CON NUMERI*/

/*aux buttons: play, pause, stop*/
.btn--primary {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: white;
    line-height: 42px;
    padding-left: 5px;
    padding-right: 5px;
    border: 2px solid var(--firstColor);
    color: var(--fourthColor);
    font-weight: 500;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    width: 80px;
    height: 30px;
    box-shadow: 1px 2px 2px 1px rgba(226, 43, 43, 0.5);
}

.btn--primary:hover{
    color: var(--thirdColor);
    background: var(--firstColor);
    box-shadow: 0 2px 2px 1px rgba(220, 220, 220, 0.6);

}

.btn--primary--active {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: var(--firstColor);
    line-height: 42px;
    padding-left: 5px;
    padding-right: 5px;
    /*border: 3px inset var(--thirdColor); */
    border-right: 1px solid var(--thirdColor);
    border-bottom: 1px solid var(--thirdColor);
    color: var(--thirdColor);
    font-weight: 500;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    width: 80px;
    height: 30px;
}

/*Da modificare -> BOTTONI SOTTO AL DISPLAY*/

.btn--secondary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    background: transparent;
    color: white;
    font-weight: 500;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.btn--secondary:hover{
    position: relative;
    background: transparent;
    color: white;
    transform: scale(1.5);

}

.btn--secondary--active {
    border-color: darkred;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.42);
}

/*Bottoni dei pattern*/

.btn--patterns{
    align-items: center;
    background: var(--fourthColor);
    line-height: 42px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0.5rem;
    border: 2px solid var(--secondColor);
    color: var(--thirdColor);
    font-weight: 500;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    width: 80px;
    height: 30px;
    box-shadow: 1px 2px 2px 1px rgba(220, 220, 220, 0.6);
}

.btn--patterns:hover{
    background: linear-gradient(var(--thirdColor), var(--secondColor));
    /* background: var(--secondColor); */
    color: var(--fourthColor);
    box-shadow: 0 2px 2px 1px rgba(220, 220, 220, 0.6);

}

.btn--patterns--active{
    align-items: center;
    background: var(--secondColor);
    padding-left: 5px;
    padding-right: 5px;
    /*border: 3px inset var(--thirdColor); */
    border-right: 1px solid var(--thirdColor);
    border-bottom: 1px solid var(--thirdColor);
    color: var(--fourthColor);
    font-weight: 500;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}

@media (max-width: 1366px) {
    .btn--secondary{
        margin-top: 0.8rem;
    }
    .btn--patterns{
        height: 20px;
        width: 90px;
    }
}