.dropdown--primary{ /*DROPDOWN PER TIME SIG AND EFFECTS*/
    width: 12rem;
    height: 3rem;
    text-align: center;
    padding-right: 0;

}

.dropdown--secondary{ /*DROPDOWN PER WET*/
    width: 7rem;
    height: 3rem;
    text-align: center;
    padding-right: 0;
}


.content--primary{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem;
    background: linear-gradient(#3C3B3D, black);
    width: 10.8rem;
    max-width: 11rem;
    z-index: 999;
    position: absolute;
    border: 1px outset white;
    border-radius: var(--borderRadSett);
    border-top: transparent;
    height: 40%;
}


.content--secondary{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem;
    background: linear-gradient(#3C3B3D, black);
    width: 6rem;
    max-width: 5.8rem;
    z-index: 999;
    position: absolute;
    border: 1px outset white;
    border-radius: var(--borderRadSett);
    border-top: transparent;
    height: 45%;
}