.button{
    padding: 1.1rem;
    color: var(--thirdColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid white;
    border-radius: var(--borderRadSett);
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.6);

}

.button:hover{
    cursor: pointer;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.6);
    border-color: black;
    border-radius: var(--borderRadSett);
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem;
    background: linear-gradient(#3C3B3D, black);
    width: 10.8rem;
    max-width: 11rem;
    z-index: 999;
    position: absolute;
    border: 1px outset white;
    border-radius: var(--borderRadSett);
    border-top: transparent;
    height: 40%;
}

.item{
    padding: 0.5rem;
    transition: all 0.2s;
    color: white;
    width: 75%;

}

.item:hover{
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.6 );
    border: 2px inset darkred;
    border-radius: var(--borderRadSett);
}

.dropLabel {
    font-size: 18px;
}

.activeDrop {
    padding: 1rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid darkred;
    border-radius: var(--borderRadSett);
}

@media (max-height: 1366px) {
    .button{
        padding: 1.1rem;
    }
    .button:hover {
        padding: 1.2rem;
        border: 1px solid black;
    }
    .activeDrop{
        padding: 1.1rem;
    }
    .dropLabel{
        font-size: 18px;
    }
}

@media (max-height: 1024px) {
    .button{
        padding: 1.1rem;
        font-size: 18px;
    }
    .button:hover {
        padding: 1.2rem;
        border: 1px solid black;
    }
    .activeDrop{
        padding: 1.1rem;
    }
    .dropLabel{
        font-size: 18px;
    }
}