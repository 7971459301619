.container {
    display: flex;
    flex-flow: row nowrap;
    width: 80%;
    align-items: center;
    justify-content: center;
}
/*  a sinistra bianco e a destra darkred, però invertirei le due cose
    così più alto diventa più rosso*/
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    outline: none;
    padding: 0;
    margin: 0;
}

/*PALLINO*/
/* bianco con alcuni px di bordo rosso*/

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border: 3px solid darkred;
    border-radius: 50%;
    cursor: pointer;
    transition: background .15s ease-in-out;
    margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    background-color: white;
}

.slider::-webkit-slider-runnable-track {
    height: 10px;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    background: darkred;

}


/*MOZILLA*/
.slider::-moz-range-track{
    background-color: white;
    height: 10px;
    border-radius: 5px;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 3px solid darkred;
    border-radius: 50%;
    cursor: pointer;
    transition: background .15s ease-in-out;
    background-color: white;
}

.slider::-moz-range-progress{
    background: darkred;
    height: 10px;
    border-radius: 5px;
}

/*FINE MOZILLA*/

.value {
    display: inline-block;
    position: relative;
    width: 60px;
    color: #fff;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    padding: 5px 10px;
    margin-left: 12px;
    font-size: 18px;
}

.value:after {
    position: absolute;
    top: 8px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: '';
}