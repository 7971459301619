.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-right: 3px solid #515151;
    background: linear-gradient(#3C3B3D, black);
    width: 30%;
    padding: 1.5% 5% 1% 5%;
}

/* Solid border */
.divider {
    display: flex;
    border-top: 3px solid #515151;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 120%;
    max-width: 28rem;

}

/* slider part*/
.slidersContainer {
    min-height: 45%;
    width: 110%;
    padding: 1%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    border: 2px solid white;
    border-radius: 5px;
    margin-top: 5%;
}

.section{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 1%;

}

.rhythmButtonsSection{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    max-height: 4.5rem;
    min-height: 4.5rem;

}

.rhythmButtonsControls{
    display: flex;
    flex-flow: row;
    margin-bottom: 1%;

}

.rhythmButtonsList{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-height: 1.9rem;
    width: 100%;
    margin: 1%;
    /*
    Possibility to play with min-height
     */
}

.playButtons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-bottom: 0rem;

}

.auxButtons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 120%;
    align-items: center;
    margin-top: 0rem;
    margin-bottom: 1.5rem;
}
.text{
    font-size: 9px;
    font-weight: bold;
    color: var(--fourthColor);
}
.text:hover{
    color: var(--thirdColor);
}

@media (max-width: 1366px) {
    .container{
        width: 30%;
        padding: 2% 5%;
    }
    .slidersContainer{
        min-height: 40%;
        height: auto;
        padding: 1rem;
        margin-top: 2rem;
    }
    .section{
        margin-bottom: 0;
    }
    .playButtons{
        margin-bottom: 0.5rem;
    }
    .divider{
        margin-top: 2rem;
    }
    .rhythmButtonsList{
        max-height: 1.9rem;
    }
    .auxButtons{
        max-height: 5rem;
    }
}

@media (max-width: 1024px) {
    .slidersContainer{
        min-height: 46.5%;
        padding-bottom: 1%;
        padding-top: 1%;
    }
    .divider{
        margin: 1.5rem 0 1.5rem 0;
    }
    .section{
        margin-bottom: 0.75rem;
    }
}