.container{
    display: flex;
    flex-direction: column;
    padding: 1%;
    background: url("../../../../assets/logo.png") center right no-repeat;
    background-size: 150px 168px;
    max-height: 90%;
    overflow: hidden;

}

.line{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.5rem;
}

.stepButton{
    display: flex;
    flex-direction: row;
}

.title{
    display: flex;
    flex-direction:row;
    align-items: center;
    width: 5.5rem;
    height: 2.5rem;
}

.title:hover{
    cursor: pointer;
    color: var(--secondColor)
}

@media (max-width: 1366px) {
    .container{
        width:  100%;
        height: auto;
        background: url("../../../../assets/logo.png") center right no-repeat;
        background-size: 150px 168px;
        padding: 0;
        margin: 0;
    }
    .line{
        height: auto;
        margin: 0.2% 0;
    }

}

@media (max-width: 1024px) {
    .container{
        width:  100%;
        height: auto;
        background: transparent;
        padding: 0;
        margin: 0;
    }
    .line{
        height: auto;
        margin: 0;
    }
    .title{
        width: 4.5rem;
        height: 1.5rem;
        margin: 0.3rem 1rem 0.3rem 0;
        /* top, right, bottom, left*/
    }

}