.container {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    background: transparent;
    padding: 1% 1%;
    margin-top: 0.1rem;

}

.sounds{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.setSounds{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

}

.arrow{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border: 2px solid var(--secondColor);
    background: var(--fourthColor);
    border-radius: 5px;
    color: var(--thirdColor);
    height: 27px;
    margin: 0 0.5rem;
    box-shadow: 0 2px 2px 1px rgba(220, 220, 220, 0.6);
}

.arrow:hover{
    background-color: var(--thirdColor);
    cursor: pointer;
    color: var(--fourthColor);
    box-shadow: 0 2px 2px 1px rgba(220, 220, 220, 0.6);
}

/* drumpad section */
.drumpad {
    height: 50%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: transparent;
    border: 2px solid white;
    border-radius: 5px;
    overflow-y: scroll;
}

.patterns{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}

@media (max-width: 1366px) {
    .container{
        width: 90%;
        padding: 0 2% 2% 2%;
    }
    .drumpad{
        min-height: 22rem;
        width: 95%;
        padding: 1% 0 0 4%;
        justify-content: space-between;
    }
}

@media (max-width: 1024px) {
    .container{
        width: 90%;
        padding: 0 2% 2% 2%;
        margin-top: 0;
    }
    .drumpad{
        height: auto;
        min-height: 43%;
        width: 95%;
        padding: 2% 0 2% 4%;

    }
}
