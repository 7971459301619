.container {
    display: flex;
    flex-direction: row;
    background: var(--eighthColor);
    height: 90vh;
    width: 90vw;
    border: 5px groove var(--sixthColor);
    background: linear-gradient(var(--fifthColor), black);
    border-radius: 5px;
}

@media (max-width: 1920px){

}

@media (max-width: 1366px) {
    .container{
        height: 86vh;
        width: 90vw;
    }
}
