.display{
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 3px solid #515151;
    border-radius: 5px;
    overflow-x: auto;
    background-color: #202020;
    max-width: 44rem;
    padding: 2% 2%;
    /*max-height: 18rem;*/
}

.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1%;
    width: 80%;
}

.title{
    display: flex;
    align-items: center;
    width: 100%;
    height: 2rem;
}


.columnTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
    margin-right: 2%;
    max-height: 105%;
}

.tab{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 120%;
    /*margin-top: 2%;*/
}

.columnSteps{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
}

.box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
}

.allDisplayBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
}

.toggle{
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    border: 2px solid var(--secondColor);

}

.toggleActive{
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    background: white;
    border: 2px solid var(--secondColor);
}
@media (max-width: 1921px) {
    .display{
        /*max-width: 55rem;*/
        /*max-height: 24rem;*/
    }

}

@media (max-width: 1366px) {
    .display {
        width: 90%;
        max-width: 42rem;
        padding: 2% 2%;
        margin: 0;
        max-height: 20rem;
    }
    .columnTitles {
        height: auto;
        max-height: 17rem;
        margin-top: 2rem;
    }
    .columnSteps{
        max-height: 19rem;
    }
    .tab {
        margin-top: 0;
    }
}
    @media (max-width: 1024px) {
        .display{
            width: 100%;
            max-width: 30rem;
            padding: 2% 2%;
            margin-bottom: 1rem;
        }
        .columnTitles{
            height: auto;
            margin-top: 1.8rem;
            margin-right: 1rem;
        }
        .tab{
            margin-top: 0;
        }
        .box{
            height: 1.8rem;
            width: 1.8rem;
        }
        .title{
            height: 1.8rem;
        }
}

